import * as React from 'react';
import Layout from './components/Layout/Layout';
import FAQAccordion from './components/FAQAccordion/FAQAccordion';
import FAQ_DATA from './../data/faq';

const FAQ = () => (
  <Layout>
    <section className="section">
      <div className="container" data-aos="fade-in">
        <h1 class="title is-4 mb-3 has-text-white is-uppercase">Une question ?</h1>
        <div className="separator has-background-info"></div>
        <FAQAccordion defaultData={FAQ_DATA}></FAQAccordion>
      </div>
    </section>
  </Layout>
)

export default FAQ;