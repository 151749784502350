const FAQ = [
  {
    active: false,
    question: 'Comment se déroule le suivi personnalisé ?',
    answer: `Je récolte les informations nécessaires à l'établissement de votre programme. Une fois le programme réalisé et envoyé, nous discutons de ce contenu afin d'être sûr que tout soit clair.\n
    À partir de ce moment je veille à ce que les séances et la diète se passent correctement et je réalise des bilans pour vérifier que l'évolution est conforme aux objectifs que nous nous sommes fixés.\n
    Je dispose également d'un compte whatsapp dédié entièrement à mes clients afin que vous puissiez me joindre à tout moment.`,
    tags: ['#Programme', '#SuiviPersonnalisé'],
  },
  {
    active: false,
    question: 'À quelle fréquence les bilans sont-ils réalisés ?',
    answer: `Les bilans se font toutes les deux semaines. Nous vérifions les données qui se rapportent à votre objectif particulier, que ce soit en poids, masse grasse, ou aspect visuel.\n
    Ces bilans me permettent par la suite de réajuster vos programmes ainsi que votre plan alimentaire afin d'être sûr que nous continuons à aller en direction de l'objectif fixé.`,
    tags: ['#Programme', '#Bilan', '#Frequence'],
  },
  {
    active: false,
    question: "Quel type de profils sportifs avez-vous l'habitude d'entraîner ?",
    answer: `Mes méthodes de travail s'adaptent à tout type de profil, allant du débutant au profil compétiteur.\n
    Chaque client est différent et mes programmations prennent en compte les caractéristiques propres à chaque personne.`,
    tags: ['#Programme', '#Profil', '#Sportif'],
  },
  {
    active: false,
    question: "À qui s'adressent vos programmes ?",
    answer: `J'ai l'habitude de travailler avec tout type de personne, aussi bien avec des hommes que des femmes.\n
    Les intensités ainsi que les différents programmes sont ajustés afin que chaque client puisse atteindre son objectif, quel qu'il soit.`,
    tags: ['#Programme', '#Profil', '#Sexe'],
  },
  {
    active: false,
    question: 'Comment peut-on vous joindre ?',
    answer: `Outre les bilans réalisés ensemble par email toutes les deux semaines.\n
    Je dispose d'un whatsapp entièrement dédié à mes clients, ce qui me permet d'être réactif et de pouvoir répondre à l'ensemble de vos questions afin d'être sûr que le programme est bien compris et que tous les mouvements soient correctement exécutés.`,
    tags: ['#Communication', '#Bilan'],
  },
  {
    active: false,
    question: 'Modifiez-vous les programmes ?',
    answer: "Les programmes sont revus au fil des bilans afin d'éviter que vous ne stagniez et, à l'inverse, pour vous garantir une progression constante au fil de notre collaboration.",
    tags: ['#Programme', '#Evolution'],
  },
  {
    active: false,
    question: "Je ne sais pas comment réaliser un exercice, comment faire ?",
    answer: `Vous disposez, à réception du programme, d'une illlustration de chacun des exercices à réaliser.\n
    Pour les exercices qui posent particulièrement problème, nous discutons par visio afin de pouvoir être sûr que ceux-ci soient exécutés de manière sécuritaire et efficace.`,
    tags: ['#Programme', '#Exercice', '#Exécution'],
  },
  {
    active: false,
    question: 'Quels moyens de paiement acceptez-vous ?',
    answer: "Les moyens de paiement que j'accepte sont les suivants : Cartes de crédit (Visa, Mastercard,Electron et American Express) ainsi que Paypal.",
    tags: ['#Programme', '#Paiement', '#CB', '#Paypal'],
  },
  {
    active: false,
    question: 'Quelle est la démarche à suivre si je souhaite arrêter mon abonnement ?',
    answer: `Je mets un point d'honneur à permettre à chaque élève d'arrêter notre collaboration dès qu'il le souhaite.\n
    Mon but n'est pas de prendre un élève en otage et de le garder malgré lui dans une collaboration qu'il souhaite quitter.\n
    Pour cette raison, je n'ai mis en place aucun préavis et aucune démarche compliqué.\n
    Si un élève désire arrêter, peu importe la raison, il lui suffit de m'en faire part et notre collaboration s'arrêtera le jour même.`,
    tags: ['#Programme', '#Stop', '#Abonnement'],
  },
];

export default FAQ;