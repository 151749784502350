import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FAQAccordion.scss';

const FAQAccordion = ({ defaultData }) => {
  const [data, setData] = useState(defaultData);

  const handleClick = (event, index) => {
    event.preventDefault();
    
    const activeIndex = data.findIndex((question) => question.active);
    const newData = [...defaultData];
    newData.forEach((question) => question.active = false);
    
    if (activeIndex !== index) {
      newData[index].active = true;
    } 

    setData(newData);
  };

  return <div className="accordion"> 
    { 
      data.map((question, index) => <div key={index}
          className={`accordion-item ${question.active ? 'active' : ''}`} 
        >
          <a href="/" className="accordion-link" onClick={(event) => handleClick(event, index) }>
            <div className="is-flex is-justify-content-center is-align-items-center">
              <span className="is-size-5 has-text-weight-semibold is-uppercase">{question.question}</span>
            </div>
            <i className="fa fa-minus"></i>
            <i className="fa fa-plus"></i>
          </a>
          <ul className="is-flex">
            {
              question.tags.map((tag) => <li className="pr-1 is-size-6 has-text-info" key={tag}>{tag}</li>)
            }
          </ul>
          <div className="answer has-text-white pt-5">
            <p>{question.answer}</p>
          </div>
          <hr />
        </div>
      )
    }
  </div>
};

FAQAccordion.propTypes = {
  defaultData: PropTypes.array.isRequired,
}

FAQAccordion.defaultProps = {
  defaultData: [],
};

export default FAQAccordion;